<template>
    <div>
        <template v-if="loading">
            <div class="flex justify-center">
                <a-spin size="small" />
            </div>
        </template>
        <template v-else>
            <div 
                v-if="actions && actions.invite" 
                :class="[!isMobile && 'flex items-center']"
                class="mb-4">
                <div class="w-full flex justify-between items-start flex-col-reverse sm:flex-row">
                    <div>
                        <template v-if="!isDepartment">
                            <div class="mb-2">
                                <i class="fi fi-rr-user-add mr-1"></i>
                                <span>
                                    Пригласить новых пользователей по 
                                </span>
                                <span 
                                    class="blue_color cursor-pointer"
                                    @click="openInvite('email')">
                                    E-mail
                                </span>
                                <span>
                                    или по 
                                </span>
                                <span 
                                    class="blue_color cursor-pointer"
                                    @click="openInvite('link')">
                                    ссылке
                                </span>
                            </div>
                        </template>
                        <UserDrawer 
                            :id="org.id"
                            multiple
                            buttonMode
                            buttonBlock
                            :submitHandler="()=> {}"
                            :buttonText="$t('wgr.invite_participants')"
                            :changeMetadata="changeMetadata"
                            :metadata="{ key: 'users', value: form.metadata }"
                            v-model="form.users">
                            <template #openButton>
                                <span class="blue_color cursor-pointer">
                                    <i class="fi fi-rr-user-add mr-1"></i>
                                    <span>
                                        Добавить существующих пользователей
                                    </span>
                                </span>
                            </template>
                        </UserDrawer>
                    </div>
                    <template v-if="!isDepartment">
                        <div class="mb-2 sm:mb-0">
                            <span
                                class="blue_color cursor-pointer"
                                @click="openInviteList()">
                                <i class="fi fi-rr-comment-alt-check mr-1"></i>
                                <span>Список приглашений</span>
                            </span>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <div>
            <component 
                :is="userComponent" 
                :parentId="parentId"
                :isAdmin="isAdmin"
                :org="org" 
                :actions="actions"
                :isDepartment="isDepartment"
                :closeDrawer="closeDrawer"
                :page_name="page_name"
                :model="model"
                :reloadMainList="reloadMainList"
                :minusUserCount="minusUserCount" 
                :updateTableRowsHeight="updateTableRowsHeight" />
        </div>
        <InvitationList :org="org" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'
import InvitationList from '../InvitationList'
import UserDrawer from '@apps/DrawerSelect/index.vue'

import Vue from 'vue'
export default {
    components: {
        InvitationList,
        UserDrawer
    },
    props: {
        org: {
            type: [Object],
            required: true
        },
        minusUserCount: {
            type: Function,
            default: () => {}
        },
        updateTableRowsHeight: {
            type: Function,
            default: () => {}
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        reloadMainList: {
            type: Function,
            default: () => {}
        },
        isDepartment: {
            type: Boolean,
            default: false
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        parentId: {
            type: String,
            default: null
        }
        
    },
    computed: {
        ...mapState({
            config: state => state.config.config,
            isMobile: state => state.isMobile,
            user: state => state.user.user
        }),
        tableSize() {
            return this.config?.theme?.tableSize ? this.config.theme.tableSize : 'small'
        },
        userComponent() {
            if(this.isMobile) 
                return () => import('./UserList.vue')
            else
                return () => import('./UserTable.vue')
        },
        orgComponent() {
            if(this.isMobile) 
                return () => import('./OrgList/ListMobile.vue')
            else
                return () => import('./OrgList')
        }
    },
    created() {
        this.getActions()
    },
    data() {
        return {
            form: {
                metadata: { users: [] },
                users: []
            },
            sort: '',
            actions: null,
            loading: false,
            model: 'users.ProfileModel',
            page_name: 'orgInfoDrawer',
            usersToAdd: []
        }
    },
    methods: {       
        
        async addUsersToOrganization() {
            const user = await this.addEmployees({
                newEmployeeList: this.value,
                parentId: this.parentId,
                key: this.organizationId,
                isDepartment: this.isDepartment,
                pageSize: this.pageSize
            })

            if(!user.created) {
                const message = `Пользователь ${user.full_name} уже состоит в 
                    ${this.isDepartment ? 'отделе' : 'организации'}`
                this.$message.warning(message)
            }
        },
        changeMetadata({key, value}) {
            Vue.set(this.addUsersForm.metadata, key, value)
        },

        openAddEmployeesDrawer() {
            this.$refs.drawerSelectUser.open()
        },
        orgCopyId() {
            try {
                navigator.clipboard.writeText(this.org.id)
                this.$message.success('Идентификатор организации скопирован')
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            }
        },
        openOrgInvite() {
            eventBus.$emit('invite_organization', this.org)
        },
        openOrgEnter() {
            eventBus.$emit('enter_organization')
        },
        async getActions() {
            try {
                this.loading = true
                const organization = this.isDepartment ? this.parentId : this.org.id
                const { data } = await this.$http.get(`/users/my_organizations/${organization}/action_info/`)
                if(data?.actions) {
                    this.actions = data.actions
                } 
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        openInvite(inviteType) {
            eventBus.$emit('open_invite', { 
                organizationId: this.org.id,
                inviteType: inviteType
            })
        },
        openInviteList() {
            eventBus.$emit(`open_inv_list_${this.org.id}`, this.org.id)
        },
    }
}
</script>